<template>
  <v-app>
    <v-toolbar app flat absolute >
      <v-toolbar-title><img src="./assets/logo.png" /></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="align-center" ><CV v-vpshow id="main-cv" /></v-toolbar-items>
    </v-toolbar>
    <v-content style="padding-top: 0;">
      <router-view></router-view>
    </v-content>
    <v-footer height="auto" id="contacts">
      <v-container g-footer>
        <v-layout wrap text-sm-center align-center>
          <v-flex sm6 offset-sm3 row text-sm-center>
            <v-layout wrap >
              <v-flex sm4 row text-xs-center text-sm-right>
                <a href="whatsapp://send?abid=79261613988&text=Hello Sergey!">WHATSAPP</a>
              </v-flex>
              <v-flex sm4 row text-xs-center>
                <a href="skype:kefiriaus?chat">SKYPE</a>
              </v-flex>
              <v-flex sm4 row text-xs-center text-sm-left>
                <a href="https://t.me/kefiriaus">TELEGRAM</a>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 wrap text-xs-center><CV v-vpshow id="footer-cv" /></v-flex>
          <v-flex xs12 wrap text-xs-center>
            <p>© {{ year() }} SP Golovkin Sergey Sergeevich, All rights reserved.</p>
          </v-flex>
        </v-layout>
      </v-container>
    </v-footer>
  </v-app>
</template>

<style lang="stylus">
  * {
    font-weight: 100 !important;
  }
  .application {
    max-width: 980px;
    margin: 0 auto;
    background: #fff !important;

    a {
      color: #000000;

      &:hover {
        text-decoration: none;
      }
    }

    section {
      margin: 0 16px;
    }

    b, strong {
      font-weight: 400 !important;
    }

    .v-container {
      padding: 16px !important;
      max-width: 980px;
    }

    .font-weight-light {
      font-weight: 100;
    }

    .v-toolbar__title {
      /*padding-top: 22px;*/
      margin-left: 16px;
    }

    nav.v-toolbar {
      background: transparent;
      color: white;

      .v-toolbar__content {
        max-width: 980px;
        margin: 0 auto;
        line-height: 0px;

        .v-toolbar__items {
          margin-right: 0px !important;

          .main-cv-before-enter {
            opacity: 0 !important;
            transform: translateY(-100%);
          }

          .main-cv-enter {
            transition-delay: 2.5s !important;
            transition: all 1.3s ease;
          }
        }
      }
    }

    .v-btn {
      border: none !important;
      border-radius: 0;
      box-shadow: none;

      .v-btn__content {
        color: #fff;
        text-transform: none;
        /*border: 1px solid #9B9B9B;*/
        width: 40px;
        height: 40px;
        background: steelblue !important;
        font-weight: 400 !important;
        border: none;
      }
    }
  }
  .v-footer {
    margin-top: 100px;
    .g-footer {
      margin-top: 25px;
      padding-top: 10px !important;
      padding-bottom: 10px !important;

      .cv {
        margin: 25px 0 45px;
      }

      .footer-cv-before-enter {
        opacity: 0;
      }

      .footer-cv-enter {
        transition-delay: 0.6s !important;
        transition: all 1.3s ease;
      }

      p {
        color: #9B9B9B;
      }
    }
  }
</style>

<script>
  export default {
    mounted() {
      this.scroll()
    },
    methods: {
      scroll() {
        let id = this.$route.hash;
        if (id) {
          id = id.toString().replace(/#/, '');

          let self = this;
          let element = document.getElementById(id);

          setTimeout(function () {
            self.$scrollTo(element);
          }, 1000);
        }
      },
      year() {
        return new Date().getFullYear()
      }
    }
  }
</script>