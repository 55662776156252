<template>
    <v-flex sm6 wrap text-md-left g-ability>
        <div class="g-ability__item">
            <div class="g-ability__item_caption g-ability__item_bg_width_90">
                <div class="g-ability__item_caption_text">RUBY ON RAILS</div>
                <div class="g-ability__item_caption_percents">90</div>
            </div>
            <div class="g-ability__item_bg g-ability__item_bg_block">
                <div id="ror" v-vpshow class="g-ability__item_bg_block g-ability__item_bg_ruby g-ability__item_bg_width_90"></div>
            </div>
        </div>
        <div class="g-ability__item">
            <div class="g-ability__item_caption g-ability__item_bg_width_80">
                <div class="g-ability__item_caption_text">DJANGO</div>
                <div class="g-ability__item_caption_percents">80</div>
            </div>
            <div class="g-ability__item_bg g-ability__item_bg_block">
                <div id="django" v-vpshow class="g-ability__item_bg_block g-ability__item_bg_django g-ability__item_bg_width_80"></div>
            </div>
        </div>
        <div class="g-ability__item">
            <div class="g-ability__item_caption g-ability__item_bg_width_90">
                <div class="g-ability__item_caption_text">BITRIX</div>
                <div class="g-ability__item_caption_percents">90</div>
            </div>
            <div class="g-ability__item_bg g-ability__item_bg_block">
                <div id="bitrix" v-vpshow class="g-ability__item_bg_block g-ability__item_bg_bitrix g-ability__item_bg_width_90"></div>
            </div>
        </div>
        <div class="g-ability__item">
            <div class="g-ability__item_caption g-ability__item_bg_width_80">
                <div class="g-ability__item_caption_text">VUE</div>
                <div class="g-ability__item_caption_percents">80</div>
            </div>
            <div class="g-ability__item_bg g-ability__item_bg_block">
                <div id="vue" v-vpshow class="g-ability__item_bg_block g-ability__item_bg_vue g-ability__item_bg_width_80"></div>
            </div>
        </div>
    </v-flex>
</template>

<style lang="stylus">
    .g-ability {
        margin-top:92px;

        &__item {
            margin-bottom: 30px;

            &_caption {
                margin-bottom: 10px;
                &_text {
                    display: inline-block;
                    width: 50%;
                    text-align: left;
                    letter-spacing: 2px;
                }
                &_percents {
                    display: inline-block;
                    width: 50%;
                    text-align: right;
                }
            }

            &_bg {
                background: #D8D8D8;

                &_slide-enter-active {
                    transition: all 1.3s ease;
                }
                &_slide-leave-active {
                    transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
                }
                &_slide-enter, &_slide-leave-to {
                    transform: translateX(-100%);
                    opacity: 0;
                }

                &_block {
                    display: block;
                    height: 7px;
                    overflow: hidden;
                }
                &_ruby {
                    background: #701516;
                }
                &_django {
                    background: #3572A5;
                }
                &_bitrix {
                    background: #4F5D95;
                }
                &_vue {
                    background: #4FC08D;
                }
                &_width_80 {
                    width: 80%;
                }
                &_width_90 {
                    width: 90%;
                }
            }
        }

        .ror, .django, .bitrix, .vue {
            &-before-enter {
                opacity: 0;
                transform: translateX(-100%);
            }

            &-enter {
                transition: all 1.3s ease;
            }
        }
    }
</style>

<script>
    export default {
      name: 'Ability'
    }
</script>