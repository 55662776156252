import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      redirect: { name: 'home', hash: 'about' }
    },
    {
      path: '/contacts',
      redirect: { name: 'home', hash: 'contacts' }
    }
  ]
})
