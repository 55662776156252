<template>
    <div class="g-tag" :style="{ 'background-color': color, 'font-size': size }"><slot></slot></div>
</template>

<style lang="stylus" scoped>
    .g-tag {
        display: inline-block;
        padding: 0px 4px;
        color: #fff;
        margin-right: 7px;
        font-weight: normal !important;
        font-size: 0.8em;
    }
</style>

<script>
  export default {
    name: 'Tag',
    props: ['color', 'size'],
  }
</script>