import Vue from 'vue'
import VueGtm from 'vue-gtm'
import App from './App.vue'
import router from './router'
import store from './store'

import VueScrollTo from 'vue-scrollto'

import Vuetify from 'vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import 'vue-awesome/icons/linkedin'
import 'vue-awesome/icons/linkedin-square'
import Icon from 'vue-awesome/components/Icon'

import Cv from './components/Cv'
import VpShow from './directives/VpShow'

import './stylus/main.styl'

Vue.use(VueGtm, {
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [] // If router, you can exclude some routes name (case insensitive) (optional)
});

Vue.use(VueScrollTo);
Vue.use(Vuetify);
Vue.config.productionTip = false;

Vue.component('CV', Cv);
Vue.component('icon', Icon);
Vue.directive('vpshow', VpShow);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
