<template>
    <section class="g-why" id="about">
        <v-layout row >
            <Description >
                <template slot="title">ABOUT</template>
                <template slot="desc">Software engineer and <b>developer</b> with <b>experience</b> of more
                    than <b>12 years</b> and abilities to resolve tasks of any difficulty
                    level. Help your project become better. </template>
                <template slot="tags">
                    <Tag color="#0db7ed">Developer</Tag>
                    <Tag color="#ff65a3">12</Tag>
                    <Tag color="#f28d1a">Years</Tag>
                </template>
            </Description>
        </v-layout>
        <v-layout row >
            <v-flex sm6 wrap text-md-left>
            </v-flex>
            <Description>
                <template slot="title">SERVICE</template>
                <template slot="desc">Professional <b>project planning</b>, <b>integration</b>, <b>development</b> and
                    <b>implementation</b> of your project of any complexity grade as
                    well as <b>clean code</b> and <b>constant testing</b> will lead your product
                    to a new business level while timely <b>technical support</b> will
                    provide stability and progress assurance. </template>
                <template slot="tags">
                    <Tag color="#0747a6">Plan</Tag>
                    <Tag color="#701516">Dev</Tag>
                    <Tag color="#4799d6">Test</Tag>
                    <Tag color="#00aff0">Support</Tag>
                </template>
            </Description>
        </v-layout>
        <v-layout row >
            <Ability />
        </v-layout>
    </section>
</template>

<style lang="stylus">
    .g-why {
        background-color: #fff !important;

        .layout:last-child {
            padding-bottom: 72px !important;
        }
    }
</style>

<script>
  import Description from '@/components/Description.vue'
  import Ability from '@/components/Ability.vue'
  import Tag from '@/components/Tag.vue'
  export default {
    name: 'Why',
    components: { Description, Ability, Tag }
  }
</script>