<template>
    <section class="g-service" >
        <v-layout row >
            <v-flex sm6 wrap text-md-left>
            </v-flex>
            <Description>
                <template slot="title">PROJECT PLANNING</template>
                <template slot="desc">Project planning that suits exclusively your needs is based on
                    a long-term experience. All individual suggestions and
                    preferences will be taken into account. </template>
                <template slot="tags">
                    <Tag color="#0747a6">Jira</Tag>
                    <Tag color="#ff65a3">Scrum</Tag>
                    <Tag color="#009688">Kanban</Tag>
                </template>
            </Description>
        </v-layout>
        <v-layout row >
            <Description>
                <template slot="title">INTEGRATION</template>
                <template slot="desc">Integration with any systems of any difficulty grade (<b>SAP</b>,
                    <b>Oracle</b>, <b>MS SQL</b>, etc) is possible both by your or specially
                    developed interaction plan. </template>
                <template slot="tags">
                    <Tag color="#2aa198">REST</Tag>
                    <Tag color="#3b5998">GraphQL</Tag>
                    <Tag color="#cccccc">SOAP</Tag>
                </template>
            </Description>
            <v-flex sm6 wrap text-md-left>
            </v-flex>
        </v-layout>
        <v-layout row >
            <v-flex sm6 wrap text-md-left>
            </v-flex>
            <Description>
                <template slot="title">DEVELOPMENT</template>
                <template slot="desc">Prompt and top-quality development where all latest trends of
                    the chosen platform will be considered. Testing and control
                    take place on all stages of the project. </template>
                <template slot="tags">
                    <Tag color="#701516">Ruby</Tag>
                    <Tag color="#3572A5">Python</Tag>
                    <Tag color="#4F5D95">Php</Tag>
                    <Tag color="#f7df1e">Javascript</Tag>
                </template>
            </Description>
        </v-layout>
        <v-layout row >
            <Description>
                <template slot="title">MODULES & PLUGINS</template>
                <template slot="desc">Development of individual modules for implementation into
                    your partner's projects as well as extracting business logic
                    into separate plugins can be done to simplify the project management. </template>
                <template slot="tags">
                    <Tag color="#701516">Gem</Tag>
                    <Tag color="#3572A5">Pip</Tag>
                    <Tag color="#f28d1a">Package</Tag>
                </template>
            </Description>
            <v-flex sm6 wrap text-md-left>
            </v-flex>
        </v-layout>
        <v-layout row >
            <v-flex sm6 wrap text-md-left>
            </v-flex>
            <Description>
                <template slot="title">OPTIMIZATION</template>
                <template slot="desc">Analysis, code refactoring and business logic optimization is
                    led during all of the launch stages to boost every system and
                    mechanism of your product. </template>
                <template slot="tags">
                    <Tag color="#205081">Git</Tag>
                    <Tag color="#0db7ed">Docker</Tag>
                    <Tag color="#4799d6">CI</Tag>
                </template>
            </Description>
        </v-layout>
        <v-layout row >
            <Description>
                <template slot="title">TECHNICAL SUPPORT</template>
                <template slot="desc">Project maintenance is performed on each stage of launch as
                    well as timely and professional technical support and advice
                    on your product's operation. </template>
                <template slot="tags">
                    <Tag color="#00aff0">Skype</Tag>
                    <Tag color="#25D366">WhatsApp</Tag>
                    <Tag color="#0088cc">Telegram</Tag>
                </template>
            </Description>
            <v-flex sm6 wrap text-md-left>
            </v-flex>
        </v-layout>
    </section>
</template>

<style lang="stylus">
    .g-service {
        background-color: #fff !important;
    }
</style>

<script>
    import Description from '@/components/Description.vue'
    import Tag from '@/components/Tag.vue'

    export default {
      name: 'Service',
      components: { Description, Tag }
    }
</script>