<template>
    <v-parallax src="/static/desk.jpg" height="200">
        <v-container align-center g-banner >
            <v-layout wrap >
                <v-flex sm12 row text-xs-center g-banner_text>
                    Let’s <b>Make Internet Better</b> Together
                </v-flex>
                <v-flex sm12 row text-xs-center g-banner_button>
                    <CV v-vpshow id="banner-cv" />
                </v-flex>
            </v-layout>
        </v-container>
    </v-parallax>
</template>

<style lang="stylus">
    .application .container.g-banner {
        //background-color: #f5f5f5;
        padding: 40px 0 !important;
        &:before {
            //background-color: #fff;
        }

        .g-banner_button {
            .banner-cv-before-enter {
                opacity: 0;
            }

            .banner-cv-enter {
                transition-delay: 0.6s !important;
                transition: all 1.3s ease;
            }
        }

        .g-banner_text {
            margin-bottom: 10px;
            font-size: 2em;

            b {
                font-weight: 400 !important;
            }
        }
    }
</style>

<script>
    export default {
      name: 'Banner'
    }
</script>
