<template>
    <v-flex sm6 wrap text-md-left g-description>
        <div class="display-1 g-description__title"><slot name="title"></slot></div>
        <div class="g-description__desc">
            <div v-vpshow class="g-description__desc_wrap"><slot name="desc"></slot><slot name="tags"></slot></div>
        </div>
    </v-flex>
</template>

<style lang="stylus" scoped>
    .g-description {
        margin-top:92px;
        margin-bottom: 0;

        &__title {
            margin-bottom: 20px;
            letter-spacing: 5px !important;
        }

        &__desc {
            color: #9B9B9B;
            line-height: 24px;
            letter-spacing: 0.4px !important;
            overflow: hidden;

            & .before-enter {
                opacity: 0;
                transform: translateY(100%);
            }

            & .enter {
                transition: all 1.0s ease;
            }
        }
    }
</style>

<script>
    export default {
      name: 'Description',
    }
</script>