<template>
  <div>
    <Main />
    <Why />
    <Banner />
    <Service />
  </div>
</template>

<style lang="stylus">
</style>

<script>
  import Main from '@/components/Main.vue'
  import Why from '@/components/Why.vue'
  import Banner from '@/components/Banner.vue'
  import Service from '@/components/Service.vue'

  export default {
    name: 'home',
    components: {
      Main, Why, Banner, Service
    }
  }
</script>