/**
 * vpshow - adding classes to elements
 *
 * id + `enter`
 * id + `before-enter`
 */
export default {
  inViewport (el) {
    let rect = el.getBoundingClientRect();
    return !(rect.bottom < 0 || rect.right < 0 ||
      rect.left > window.innerWidth ||
      rect.top > window.innerHeight)
  },

  bind(el, binding) {
    let id = el.getAttribute('id');
    let enter = 'enter';
    let before_enter = 'before-enter';
    if (id) {
      enter = id + '-' + enter;
      before_enter = id + '-' + 'before-enter';
    }
    el.classList.add(before_enter);
    el.$onScroll = function() {
      if (binding.def.inViewport(el)) {
        el.classList.add(enter);
        el.classList.remove(before_enter);
        binding.def.unbind(el, binding)
      }
    };
    document.addEventListener('scroll', el.$onScroll)
  },

  inserted(el) {
    el.$onScroll()
  },

  unbind(el) {
    document.removeEventListener('scroll', el.$onScroll);
    delete el.$onScroll
  }
};