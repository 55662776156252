<template><a class="cv-url" href="CV.pdf" target="_blank"><v-btn class="btn--flat cv" >CV</v-btn></a></template>

<style lang="stylus">
    .cv-url {
        text-decoration: none;

        .cv {
            height: 40px;
            padding: 0 !important;
        }
    }
</style>

<script>
    export default {
      name: 'Cv'
    }
</script>