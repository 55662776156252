<template>
    <v-jumbotron class="g-main" height="600px" >
        <v-container fill-height style="padding-bottom: 70px; max-width: 980px;">
            <v-layout row align-center >
                <v-flex md12 sm12 wrap text-md-right  style="margin-top: 110px;">
                    <h1 v-vpshow id="email" class="display-3 text-sm-right text-xs-right font-weight-light">
                        <a href="mailto:sergey@golovk.in"><span class="g-main__letter_spacing_5">SERGEY@GOLOV</span><span class="g-main__etter_spacing_0">K.</span><span class="g-main__letter_spacing_5">I</span><span class="g-main__etter_spacing_0">N</span></a></h1>
                    <div v-vpshow id="description" class="title text-sm-right text-xs-right font-weight-light" >
                        <span style="">professional developer</span>
                    </div>
                    <div v-vpshow id="linked-in" class="icons text-sm-right text-xs-right">
                        <a href="http://linkedin.com/in/sergey-golovkin/" target="_blank"><icon name="linkedin" /></a>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </v-jumbotron>
</template>

<style lang="stylus" scoped>
    @import '../stylus/main.styl'
    .g-main {
        background: url(../assets/pic.jpg) 0 0 no-repeat;

        h1 {
            a {
                background-color: white;
                padding: 0 5px 2px;
            }
        }

        div.title {
            span {
                background-color: white;
                display: inline-block;
                margin-top: 20px;
                padding: 3px 0 3px 5px;
                letter-spacing: 5px;
            }
        }

        div.icons {
            svg {
                color: #fff;
                width: 35px;
                height: 35px;
                margin-top: 20px;
                padding: 3px;
                background: steelblue;
            }
        }

        &__letter_spacing_0 {
            letter-spacing: 0;
        }

        &__letter_spacing_5 {
            letter-spacing: 5px;
        }

        #email {
            transition-delay: 0.5s;
        }
        .email-before-enter {
            transform: translateX(100%);
        }
        .email-enter {
            transition: all 1.3s ease;
        }

        #description {
            transition-delay: 1.0s;
        }
        .description-before-enter {
            transform: translateX(100%);
        }
        .description-enter {
            transition: all 1.3s ease;
        }

        #linked-in {
            transition-delay: 1.5s;
        }
        .linked-in-before-enter {
            opacity: 0;
            transform: translateX(100%);
        }
        .linked-in-enter {
            transition: all 1.3s ease;
        }
    }

    @media only screen and (max-width: 860px) {
        .g-main {
            background-position: -120px 0;
        }
    }
</style>

<script>
  export default {
    name: 'Main'
  }
</script>